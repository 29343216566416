<template>
  <b-modal v-model="value" :centered="centered" hide-header-close hide-footer :size="size" :title="title">
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: 'AModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    centered: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {

    }
  }
}
</script>
