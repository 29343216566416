<template>
  <div class="update-email">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row>
        <b-col md="6" sm="12">
          <h3 class="mb-2">
            Mail Değiştir
          </h3>
          <validation-observer
              v-if="user"
              ref="loginForm"
              #default="{invalid}"
          >
            <b-form
                class="mt-2"
                @submit.prevent
            >

              <b-form-group
                  label-for="currentEmail"
                  label="Mevcut Mail Adresi"
              >
                <b-form-input
                    id="currentEmail"
                    v-model="user.email"
                    name="currentEmail"
                    disabled
                    readonly
                />
              </b-form-group>

              <b-form-group
                  label-for="email"
                  label="Yeni Mail Adresi"
              >
                <validation-provider
                    #default="{ errors }"
                    name="E-posta"
                    vid="mail"
                    rules="required|email"
                >
                  <b-form-input
                      id="email"
                      v-model="email"
                      name="email"
                      :state="errors.length > 0 ? false:null"
                      autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                  variant="primary"
                  class="mt-2 px-4"
                  @click="update"
              >
                Kaydet
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BOverlay, BRow } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LoadingSpinner from '@/components/LoadingSpinner'
import { showNotify, throwDefaultError } from "@/helpers/helpers";

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,

    LoadingSpinner,

    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      email: '',
      loading: false,
      user: null
    }
  },
  created() {
    this.getProfile()
  },
  methods: {
    getProfile() {
      this.loading = true
      useJwt.getMe()
          .then(response => {
            this.user = response.data.body
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    update() {
      this.loading = true
      useJwt.updateMe({email: this.email})
          .then(response => {
            showNotify(this.$toast, 'Güncellenme başarılı', 'success')
            this.getProfile()
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>
