<template>
  <section class="page" id="page-settings">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row style="position: relative">
        <b-col sm="12">
          <h2 class="title">
            {{ $t('Settings') }}
          </h2>
        </b-col>
        <b-col class="left-menu" md="4" sm="12">
          <div v-if="user && user.profile.gender !== 'female'" class="online-status">
            <div>
              <h4>
                {{ $t('Be Online') }}
              </h4>
              <p>
                {{ $t('You can allow other users to call you or turn it off') }}
              </p>
            </div>
            <div>
              <b-form-checkbox
                  v-model="onlineStatus"
                  class="custom-control-dark"
                  switch
                  button-variant="primary"
                  :disabled="!user"
              />
            </div>
          </div>
          <b-button v-if="dropdownMenuShow" block @click="dropdownIsOpen = !dropdownIsOpen"
                    variant="outline-primary">
            <span>
              {{ $t('Settings Menu') }}
            </span>
            <font-awesome-icon v-if="dropdownIsOpen" icon="fa-solid fa-chevron-up"/>
            <font-awesome-icon v-else icon="fa-solid fa-chevron-down"/>
          </b-button>
          <input type="checkbox" style="display: none" v-model="dropdownIsOpen">
          <div v-show="dropdownIsOpen" class="custom-dropdown-menu">
            <router-link class="menu-item" :class="{active: $route.name === 'settings.profile-information'}"
                         :to="{name: 'settings.profile-information'}">
              <span>
                {{ $t('Profile Information') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <router-link class="menu-item" :class="{active: $route.name === 'settings.calling'}"
                         :to="{name: 'settings.calling'}">
              <span>
                {{ $t('Calling Settings') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <router-link v-if="false" class="menu-item" :class="{active: $route.name === 'settings.interests'}"
                         :to="{name: 'settings.interests'}">
              <span>
                {{ $t('Interest Settings') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <router-link v-if="false" class="menu-item" :class="{active: $route.name === 'settings.filters'}"
                         :to="{name: 'settings.filters'}">
              <span>
                {{ $t('Filtering Settings') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <router-link class="menu-item" :class="{active: $route.name === 'settings.notifications'}"
                         :to="{name: 'settings.notifications'}">
              <span>
                {{ $t('Notification Settings') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <router-link class="menu-item" :class="{active: $route.name === 'settings.about-us'}"
                         :to="{name: 'settings.about-us'}">
              <span>
                {{ $t('About Us') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <router-link class="menu-item" :class="{active: $route.name === 'settings.contact'}"
                         :to="{name: 'settings.contact'}">
              <span>
                {{ $t('Contact Us') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <div class="menu-item"
                 @click="showAccountSettings = !showAccountSettings"
                 :class="{active: hasActiveRoute(['settings.remove-account', 'settings.update-password'])}">
              <span>
                {{ $t('Account Settings') }}
              </span>
              <span>
                <font-awesome-icon v-if="!showAccountSettings" icon="fa-solid fa-plus" />
                <font-awesome-icon v-else icon="fa-solid fa-minus"/>
              </span>
            </div>
            <transition name="slide-down">
              <div v-if="showAccountSettings" class="submenu">
<!--                <router-link class="menu-item" :class="{active: $route.name === 'settings.profile-information'}"-->
<!--                             :to="{name: 'settings.profile-information'}">-->
<!--                  <span>-->
<!--                    {{ $t('Profile') }}-->
<!--                  </span>-->
<!--                  <span>-->
<!--                    <font-awesome-icon icon="fa-solid fa-chevron-right"/>-->
<!--                  </span>-->
<!--                </router-link>-->
                <!--            <router-link class="menu-item" :class="{active: $route.name === 'settings.update-password'}"-->
                <!--                 :to="{name: 'settings.update-password'}">-->
                <!--              <span>-->
                <!--                Şifre Değiştirme-->
                <!--              </span>-->
                <!--              <span>-->
                <!--                <font-awesome-icon icon="fa-solid fa-chevron-right"/>-->
                <!--              </span>-->
                <!--            </router-link>-->
                <router-link class="menu-item" :class="{active: $route.name === 'settings.remove-account'}"
                             :to="{name: 'settings.remove-account'}">
                  <span>
                    {{ $t('Delete Account') }}
                  </span>
                  <span>
                    <font-awesome-icon icon="fa-solid fa-chevron-right"/>
                  </span>
                </router-link>
              </div>
            </transition>
            <router-link class="menu-item" :class="{active: $route.name === 'settings.faq'}"
                         :to="{name: 'settings.faq'}">
              <span>
                {{ $t('F.A.Q.')}}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <router-link class="menu-item" :class="{active: $route.name === 'settings.contracts'}"
                         :to="{name: 'settings.contracts'}">
              <span>
                {{ $t('Membership Agreements') }}
              </span>
              <span>
                <font-awesome-icon icon="fa-solid fa-chevron-right"/>
              </span>
            </router-link>
            <div class="menu-item" @click="logoutModal = true">
              <span>
                 {{ $t('Logout') }}
              </span>
            </div>
          </div>
        </b-col>
        <b-col class="page-setting" md="8" sm="12">
          <router-view :key="$route.fullPath"></router-view>
          <KeepAlive>
            <!--          <component :is="activeComponent" />-->
          </KeepAlive>
        </b-col>
      </b-row>

      <b-modal v-model="logoutModal" centered hide-header-close hide-footer size="sm">
        <div class="mb-3">
          {{ $t('Are you sure you want to logout Boho Live?') }}
        </div>
        <b-button class="px-4 mr-2" variant="light" size="sm" @click="logoutModal = false">
          {{ $t('Cancel') }}
        </b-button>
        <b-button class="px-4" variant="primary" size="sm" @click="logout">
          {{ $t('Logout') }}
        </b-button>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import { BButton, BCol, BDropdown, BDropdownItem, BDropdownItemButton, BFormCheckbox, BModal, BOverlay, BRow } from 'bootstrap-vue'
import AboutUs from './components/AboutUs'
import Contact from './components/Contact'
import Contracts from './components/Contracts'
import Interests from './components/Interests'
import RemoveAccount from './components/RemoveAccount'
import SSS from './components/SSS'
import UpdateEmail from './components/UpdateEmail'
import UpdatePassword from './components/UpdatePassword'
import UpdatePhone from './components/UpdatePhone'
import { gtmTrackNewEvent, logout, throwDefaultError } from '@/helpers/helpers'
import AModal from '@/components/AModal'
import useJwt from '@/auth/jwt/useJwt'

// import { getUser } from '@/auth/utils'

export default {
  components: {
    AModal,

    BButton,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormCheckbox,
    BModal,
    BOverlay,
    BRow,

    AboutUs,
    Contact,
    Contracts,
    Interests,
    RemoveAccount,
    SSS,
    UpdateEmail,
    UpdatePassword,
    UpdatePhone,
  },
  data() {
    return {
      activeComponent: 'UpdateEmail',
      dropdownIsOpen: true,
      dropdownMenuShow: false,
      loading: false,
      logoutModal: false,
      onlineStatus: false,
      showAccountSettings: false,
      user: null,
      watchOnlineStatus: false
    }
  },
  created() {
    this.getMe()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.onResize()
  },
  updated() {
    this.onResize(true)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    userOnlineStatus() {
      return this.$store.getters['auth/userOnlineStatus']
    }
  },
  watch: {
    onlineStatus(val) {
      if (this.watchOnlineStatus) {
        this.updateOnlineStatus()

        gtmTrackNewEvent('changeonlinestatus', {
          category: 'Hesap',
          action: 'ChangeOnlineStatus',
          label: val ? 'Online' : 'Offline',
        })
      }
    },
    $route(to, from) {
      this.dropdownIsOpen = false
    },
    userOnlineStatus(val) {
      this.watchOnlineStatus = false
      this.onlineStatus = val === 'online'
      setTimeout(() => {
        this.watchOnlineStatus = true
      }, 11)
    }
  },
  methods: {
    getMe() {
      useJwt.getMe()
          .then(response => {
            this.watchOnlineStatus = false
            this.user = response.data.body
            this.onlineStatus = this.user.profile.onlineStatus === 'online'
            this.$store.dispatch('auth/updateUser', this.user)
            setTimeout(() => {
              this.watchOnlineStatus = true
            }, 11)
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
    },
    hasActiveRoute(names) {
      let result = false
      names.forEach(name => {
        if (name === this.$route.name) {
          result = true
        }
      })
      return result
    },
    logout() {
      gtmTrackNewEvent('logout', {
        category: 'Hesap',
        action: 'Logout',
      })

      logout(this.$ability, this.$router)
    },
    onResize(isUpdated = false) {
      if (window.innerWidth <= 770) {
        if (!isUpdated ) {
          this.dropdownIsOpen = false
          this.dropdownMenuShow = true
        }
      } else {
        this.dropdownIsOpen = true
        this.dropdownMenuShow = false
      }
    },
    updateOnlineStatus() {
      this.loading = true

      useJwt.updateOnlineStatus(this.onlineStatus ? 'online' : 'offline')
          .then(response => {
            this.onlineStatus = response.data.body.onlineStatus === 'online'
            this.getMe()
          })
          .catch(() => {

          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss">
#page-settings {
  .left-menu {
    margin-top: -7px;
    background-color: #F5F8FD;
    padding: 20px;
    @media only screen and (min-device-width: 768px) {
      min-height: calc(100vh - 190px);
    }

    .online-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      background: rgba(115, 124, 139, 0.1);
      border-radius: 12px;
      padding: 15px;
      margin-bottom: 8px;

      h4 {
        font-family: 'Lexend', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FEB500;
      }

      p {
        font-family: 'Lexend', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #888888;
      }
    }

    .submenu {
      padding-left: 30px;
    }

    .menu-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 16px;
      padding: 8px 4px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }
    .menu-item.active {
      font-weight: 600;
      color: #000;
    }
  }
  .page-setting {
    color: #000;
    padding: 40px;
    h3 {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #000;
    }
    .notifications {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
